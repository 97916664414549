import React, { useState, useEffect } from "react"
import { css } from "@emotion/core"
import AV from "leancloud-storage"

import SPALayout from "../components/pmb-layout"
import useMessage from "../utils/message"
import Editor from "rich-markdown-editor"

import "../routes/notes/md-style.css"
import { dictionary } from "../routes/notes/editor-config"
import timeAgoFormat from "src/utils/timeago"
import Logo from "src/components/workspace/logo"

const Share = React.memo(() => {
  const message = useMessage()

  let [note, setNote] = useState(null)
  let [error, setError] = useState("Loading...")

  useEffect(() => {
    const defaultId = window.location.search.replace(/^\?/, "")
    if (
      defaultId === "" ||
      defaultId === undefined ||
      defaultId.length !== 24
    ) {
      setError("您的链接出了点问题，请访问完整的链接~~")
      return
    }

    const query = new AV.Query<AV.Object>("notes")
    query
      .get(defaultId)
      .then(note => {
        setNote(note)
      })
      .catch(e => {
        switch (e.code) {
          case 101:
            setError("抱歉，您访问的内容不存在~~")
            break
          case 403:
          case 109:
            setError(
              "抱歉，您无权访问这个内容，用户未分享或者分享已经被取消了。"
            )
            break
        }
      })
  }, [])

  return note ? (
    <div
      css={css`
        max-width: 800px;
        padding: 30px 10px 10px;
        box-sizing: border-box;
        margin: 0 auto;
      `}
    >
      <h1
        css={css`
          text-align: center;
        `}
      >
        {note.get("title")}
      </h1>
      <p
        css={css`
          margin: 10px 0 20px;
          display: flex;
          justify-content: space-between;
        `}
      >
        <span>
          {timeAgoFormat(new Date(note.updatedAt))}
          更新
        </span>
      </p>
      <Editor
        className="ubug-md"
        readOnly
        dark={true}
        dictionary={dictionary}
        placeholder="内容好像遗失在了宇宙中..."
        defaultValue={note.get("content")}
        value={note.get("content")}
        onChange={() => { }}
        onCancel={() => console.log("Cancel triggered")}
        onClickLink={href => console.log("Clicked link: ", href)}
        onClickHashtag={tag => console.log("Clicked hashtag: ", tag)}
        onShowToast={msg => message.info(msg)}
      />
      <div
        css={css`
          display: flex;
          align-items: center;
          justify-content: center;
          color: #686868;
          &::before,
          &::after {
            content: "";
            background: #2f2f2f;
            height: 1px;
            width: 100px;
            flex: auto;
            display: block;
            margin-right: 0px;
          }
          &::after {
            margin-left: 8px;
          }
        `}
      >
        <a href="/" title="UFlow - work flow for your own!" target="_blank">
          <Logo
            plain
            size="2em"
            color="#686868"
            css={css`
              margin-right: 5px !important;
            `}
          />
        </a>
        全文完
      </div>
    </div>
  ) : (
      <div
        css={css`
        text-align: center;
        padding: 40px;
      `}
      >
        <Logo plain color="#FFFFFF" />
        <div
          css={css`
          margin: 40px 0 20px;
        `}
        >
          {error}
        </div>
        <div>
          <a href="/" title="UFlow - work flow for your own!" target="_blank">
            UFlow
        </a>
        </div>
      </div>
    )
})

export default () => (
  <SPALayout>
    <Share />
  </SPALayout>
)
